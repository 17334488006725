import React, { useState } from 'react';
import { Input, Table, Card } from 'antd';
import moment from 'moment';
import { Select } from 'antd/es';
export var IntakesList = function (props) {
    var _a = useState(null), editingId = _a[0], setEditingId = _a[1];
    var columns = [
        {
            title: 'ID',
            dataIndex: 'id',
        },
        {
            title: 'Product',
            dataIndex: 'product',
            render: function (t, record) {
                return (React.createElement("div", { style: { display: 'flex', flexWrap: 'wrap', flexDirection: 'column' } },
                    React.createElement("div", { style: { fontWeight: 'bold' } }, record.sales_order_lineitem.name),
                    React.createElement("div", { style: { fontSize: '11px' } }, record.sales_order_lineitem.product_description)));
            },
        },
        {
            title: 'Quantity',
            dataIndex: 'quantity',
            width: 150,
            render: function (t, record) {
                if (record.id === editingId) {
                    return (React.createElement("div", null,
                        React.createElement(Input, { style: { width: '50%' }, defaultValue: record.quantity, onChange: function (value) {
                                props.updateLocalQuantity(props.intake.id, record.id, Number(value.target.value));
                            } }),
                        React.createElement("a", { style: { paddingLeft: '10px' }, onClick: function () {
                                props.updateIntakeItem({ quantity: record.quantity }, record.id);
                                setEditingId(null);
                            } }, "save")));
                }
                return (React.createElement("div", null,
                    React.createElement("span", null, record.quantity),
                    React.createElement("a", { style: { paddingLeft: '10px' }, onClick: function () { return setEditingId(record.id); } }, "edit")));
            },
        },
        {
            title: 'Extra Quantities / Status',
            dataIndex: 'extra_quantities',
            width: 250,
            render: function (string, record) {
                return (React.createElement("div", null, record.extra_quantities > 0 ? (React.createElement(React.Fragment, null,
                    string,
                    " / ",
                    record.status)) : (0)));
            },
        },
        {
            title: 'Storage Location',
            dataIndex: 'storage_location',
            render: function (storage, record) {
                return (React.createElement(Select, { placeholder: 'Select a storage', defaultValue: record.storage_location, style: { width: '100%' }, onChange: function (storage) {
                        props.updateIntakeItem({ storage_location: storage }, record.id);
                    } }, props.storage_locations.map(function (storageLocation, index) {
                    return (React.createElement(React.Fragment, null, storageLocation.numbers.map(function (number) {
                        return (React.createElement(Select.Option, { key: index, value: storageLocation.initial + '-' + number + ': ' + storageLocation.name, label: storageLocation.initial + '-' + number + ': ' + storageLocation.name },
                            storageLocation.initial + '-' + number,
                            ": ",
                            storageLocation.name));
                    })));
                })));
            },
        },
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(Card, { title: React.createElement("strong", null, props.intake.display), extra: React.createElement("strong", null, moment(props.intake.created_time).format('MM/DD/YYYY HH:mm')), size: 'small', style: { width: '50%' } },
            React.createElement("span", null, "To change the storage location simply select it from the drop-down list."),
            React.createElement(Table, { bordered: true, loading: props.loading, dataSource: props.intake.lineitem_intakes.filter(function (lineitem) { return lineitem.quantity !== 0; }), columns: columns, rowClassName: "editable-row", pagination: false }))));
};
