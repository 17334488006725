import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { PartItem } from '../components/Parts/PartItem';
import { StepBackwardOutlined, StepForwardOutlined } from '@ant-design/icons';
import { Button, Col, Collapse, notification, Row, Tooltip, Typography } from 'antd';
import { QuoteStore } from '../Stores';
import { ThreeDViewer } from '@partsbadger/threedviewer';
import { MasterProductDetail } from '../components/MasterProduct';
import { ApplicationCostItems, QuoteNotes, QuoteTotals, RFQView, TooltipFlag } from '../components';
import { AdditionalRequirements, ErrorRender } from '@partsbadger/library';
import { useHotkeys } from 'react-hotkeys-hook';
import { patch } from '@partsbadger/utils';
import { AccountOverview, RfqInformation, RfqOverview } from '../components/Collapses';
import dayjs from 'dayjs';
var Text = Typography.Text, Paragraph = Typography.Paragraph;
function getNext(current, list) {
    var next = list[0];
    var index = list.findIndex(function (id) { return id === current; });
    if (index > -1) {
        index = index + 1;
        if (index < list.length) {
            next = list[index];
        }
    }
    return next;
}
function getPrev(current, list) {
    var next = list[list.length - 1];
    var index = list.findIndex(function (id) { return id === current; });
    if (index > -1) {
        index = index - 1;
        if (index > -1) {
            next = list[index];
        }
    }
    return next;
}
var LayoutV2 = function (_a) {
    var _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9, _10, _11, _12, _13, _14, _15, _16, _17, _18, _19, _20, _21, _22, _23, _24, _25, _26, _27, _28, _29, _30, _31, _32, _33, _34, _35, _36, _37, _38, _39, _40;
    var quote = _a.quote, user = _a.user, account = _a.account, master_products = _a.master_products, rfq_id = _a.rfq_id, selectedLineItems = _a.selectedLineItems, handleSelectLineItems = _a.handleSelectLineItems, additional_requirements = _a.additional_requirements, setAdditionalRequirements = _a.setAdditionalRequirements, setShowQuoteNotificationDrawer = _a.setShowQuoteNotificationDrawer, setReorderingAppCost = _a.setReorderingAppCost;
    var _41 = useState(false), loading = _41[0], setLoading = _41[1];
    var _42 = useState(0), new_index = _42[0], setNewIndex = _42[1];
    var _43 = useState([]), masterProducts = _43[0], setMasterProducts = _43[1];
    var master_product = QuoteStore.selected_master_product;
    var master_products_ids = master_products.map(function (item) { return item.master_product.id; });
    var quote_line_items = QuoteStore.lineItems;
    var quote_id = ((_b = QuoteStore === null || QuoteStore === void 0 ? void 0 : QuoteStore.quote) === null || _b === void 0 ? void 0 : _b.id) || 0;
    useEffect(function () {
        var _a;
        setMasterProducts(master_products);
        if (quote.stage === 'In Progress') {
            setShowQuoteNotificationDrawer(true);
        }
        QuoteStore.setSelectedMasterProduct((_a = master_products[0]) === null || _a === void 0 ? void 0 : _a.master_product);
    }, [master_products]);
    var handlePrevOrNext = function (type) {
        var next_master_product_id = -1;
        if (type === 'next') {
            next_master_product_id = getNext(master_product === null || master_product === void 0 ? void 0 : master_product.id, master_products_ids);
        }
        else {
            next_master_product_id = getPrev(master_product === null || master_product === void 0 ? void 0 : master_product.id, master_products_ids);
        }
        var selected = master_products.find(function (item, index) {
            setNewIndex(index);
            return item.master_product.id === next_master_product_id;
        });
        QuoteStore.setSelectedMasterProduct(selected.master_product);
        location.href = "#".concat(selected.master_product.name).concat(selected.master_product.id);
    };
    //hotkeys
    var _44 = useState(false), next = _44[0], setNext = _44[1];
    var _45 = useState(false), prev = _45[0], setPrev = _45[1];
    useEffect(function () {
        if (next) {
            handlePrevOrNext('next');
            setNext(false);
        }
        if (prev) {
            handlePrevOrNext('prev');
            setPrev(false);
        }
    }, [next, prev]);
    useHotkeys('shift + n', function () { return setNext(true); });
    useHotkeys('shift + p', function () { return setPrev(true); });
    return (React.createElement("div", { className: 'quote-tool flex', style: {
            height: '100%',
        } },
        React.createElement("div", { className: 'aside', style: {
                width: '12%',
                height: '100%',
            } },
            React.createElement("div", { className: 'content-parts p-4 border', style: {
                    height: '60vh',
                    overflowY: 'auto',
                } }, masterProducts.map(function (row, index) {
                var quote_line_items_by_master_product = quote_line_items.filter(function (item) { return item.product.master_product === row.master_product.id; });
                return (React.createElement(PartItem, { id: row.master_product.name, key: row.master_product.id, selected: (master_product === null || master_product === void 0 ? void 0 : master_product.id) === row.master_product.id, 
                    //@ts-ignore
                    line_items: quote_line_items_by_master_product, master_product: row.master_product, handleClick: function () {
                        //@ts-ignore
                        QuoteStore.setSelectedMasterProduct(row.master_product);
                        setNewIndex(index);
                    }, handleUpdateName: function (name) {
                        patch('/staff/master-products/' + row.master_product.id + '/', { name: name })
                            .then(function () {
                            QuoteStore.getMasterProducts(quote_id);
                        })
                            .catch(function (error) {
                            notification.error({ message: React.createElement(ErrorRender, { error: error }) });
                        });
                    } }));
            })),
            React.createElement("div", { className: 'part-actions w-full flex flex-row items-center shadow', style: {
                    height: '5%',
                } },
                React.createElement("div", { className: 'w-full flex flex-row items-center m-auto justify-between max-w-xs shadow p-4' },
                    React.createElement(Tooltip, { title: "Shift + p" },
                        React.createElement(Button, { size: 'large', type: 'primary', icon: React.createElement(StepBackwardOutlined, null), onClick: function () { return handlePrevOrNext('prev'); } }, "Prev Part")),
                    React.createElement(Tooltip, { title: "Shift + n" },
                        React.createElement(Button, { size: 'large', type: 'primary', icon: React.createElement(StepForwardOutlined, null), onClick: function () { return handlePrevOrNext('next'); } }, "Next Part")))),
            React.createElement("div", { className: 'rfq-view shadow', style: {
                    // width: '20%',
                    height: '35%',
                    overflowY: 'auto',
                } },
                React.createElement(RfqOverview, { quote_type: quote.type, rfq_submission_method: ((_c = QuoteStore.rfq) === null || _c === void 0 ? void 0 : _c.submission_method) || '', rfq_submission_date: QuoteStore.rfq ? dayjs(QuoteStore.rfq.created_time).format('LLL') : '', quote_number: quote.id, rfq_number: QuoteStore.rfq ? (_d = QuoteStore.rfq) === null || _d === void 0 ? void 0 : _d.id : null, rfq_status: ((_e = QuoteStore.rfq) === null || _e === void 0 ? void 0 : _e.status) || '', quote_design_engineer: ((_f = quote.design_engineer) === null || _f === void 0 ? void 0 : _f.fullname) || '', quote_custom_rfq_reference: ((_h = (_g = QuoteStore.rfq) === null || _g === void 0 ? void 0 : _g.quote) === null || _h === void 0 ? void 0 : _h.customer_rfq_reference) || '', rfq_shipping_name: ((_j = QuoteStore.rfq) === null || _j === void 0 ? void 0 : _j.shipping_name) || '', rfq_shipping_street: ((_k = QuoteStore.rfq) === null || _k === void 0 ? void 0 : _k.shipping_street) || '', rfq_shipping_state: ((_l = QuoteStore.rfq) === null || _l === void 0 ? void 0 : _l.shipping_state) || '', rfq_shipping_country: ((_m = QuoteStore.rfq) === null || _m === void 0 ? void 0 : _m.shipping_country) || '', rfq_shipping_zip_code: ((_o = QuoteStore.rfq) === null || _o === void 0 ? void 0 : _o.shipping_zip_code) || '' }),
                React.createElement("div", { className: 'p-4' },
                    React.createElement(Collapse, null,
                        React.createElement(Collapse.Panel, { header: React.createElement("div", { className: 'font-bold' }, "RFQ Products"), key: "1" }, rfq_id && React.createElement(RFQView, { rfq_id: rfq_id })))))),
        React.createElement("div", { className: 'content-right', style: {
                width: '88%',
                height: '100%',
            } },
            React.createElement(Row, { justify: "center" },
                React.createElement(Col, { xs: 24, sm: 12 },
                    React.createElement(AccountOverview, { flags: QuoteStore.flagsAccountOverview, account_name: ((_p = quote.account) === null || _p === void 0 ? void 0 : _p.name) || '', sales_rep: ((_q = quote.owner) === null || _q === void 0 ? void 0 : _q.fullname) || '', contact_name: ((_r = quote.contact) === null || _r === void 0 ? void 0 : _r.fullname) || '', contact_email: ((_s = quote.contact) === null || _s === void 0 ? void 0 : _s.email) || '', close_rate: ((_t = QuoteStore.quoteAccountOverview) === null || _t === void 0 ? void 0 : _t.close_rate) || 0, average_margin: ((_u = QuoteStore.quoteAccountOverview) === null || _u === void 0 ? void 0 : _u.average_margin) || 0, margin_stddev: ((_v = QuoteStore.quoteAccountOverview) === null || _v === void 0 ? void 0 : _v.margin_stddev) || 0, lifetime_value: ((_x = (_w = QuoteStore.quoteAccountOverview) === null || _w === void 0 ? void 0 : _w.lifetime_value) === null || _x === void 0 ? void 0 : _x.toLocaleString()) || '', most_recent_deal_stage: ((_z = (_y = QuoteStore.quote) === null || _y === void 0 ? void 0 : _y.deal) === null || _z === void 0 ? void 0 : _z.stage) || '', lost_reason: ((_0 = quote.deal) === null || _0 === void 0 ? void 0 : _0.lost_reason) || '', deal_stage: ((_2 = (_1 = QuoteStore.quote) === null || _1 === void 0 ? void 0 : _1.deal) === null || _2 === void 0 ? void 0 : _2.stage) || null })),
                React.createElement(Col, { xs: 24, sm: 12 },
                    React.createElement(RfqInformation, { flags: QuoteStore.flagsRfqInformation, rfq_account_name: ((_4 = (_3 = QuoteStore.rfq) === null || _3 === void 0 ? void 0 : _3.account) === null || _4 === void 0 ? void 0 : _4.name) || '', quote_has_batch_shipments: quote.has_batch_shipments, quote_whole_quote_adjust: quote.whole_quote_adjust, rfq_other_notes: ((_5 = QuoteStore.rfq) === null || _5 === void 0 ? void 0 : _5.other_notes) || '', quote_customer_statement: ((_7 = (_6 = QuoteStore.rfq) === null || _6 === void 0 ? void 0 : _6.quote) === null || _7 === void 0 ? void 0 : _7.customer_statement) || '', rfq_itar: ((_8 = QuoteStore.rfq) === null || _8 === void 0 ? void 0 : _8.itar) || false, rfq_needs_to_create_2d_files: ((_9 = QuoteStore.rfq) === null || _9 === void 0 ? void 0 : _9.needs_to_create_2d_files) || false, rfq_needs_to_create_3d_files: ((_10 = QuoteStore.rfq) === null || _10 === void 0 ? void 0 : _10.needs_to_create_3d_files) || false, quote_custom_requirements: ((_12 = (_11 = QuoteStore.rfq) === null || _11 === void 0 ? void 0 : _11.quote) === null || _12 === void 0 ? void 0 : _12.custom_requirements) || [], quote_customer_supplied_material: ((_14 = (_13 = QuoteStore.rfq) === null || _13 === void 0 ? void 0 : _13.quote) === null || _14 === void 0 ? void 0 : _14.customer_supplied_material) || false, quote_domestic_quoting: ((_16 = (_15 = QuoteStore.rfq) === null || _15 === void 0 ? void 0 : _15.quote) === null || _16 === void 0 ? void 0 : _16.domestic_quoting) || false, quote_requote_changes: ((_18 = (_17 = QuoteStore.rfq) === null || _17 === void 0 ? void 0 : _17.quote) === null || _18 === void 0 ? void 0 : _18.requote_changes) || [], rfq_custom_additional_requirements: ((_19 = QuoteStore.rfq) === null || _19 === void 0 ? void 0 : _19.custom_additional_requirements) || '', rfq_target_lead_time: ((_20 = QuoteStore.rfq) === null || _20 === void 0 ? void 0 : _20.target_lead_time) || '' }))),
            React.createElement("div", { className: 'part-viewers flex justify-content', style: {
                    height: '75vh',
                } }, (master_product === null || master_product === void 0 ? void 0 : master_product.id) ? (React.createElement(React.Fragment, null,
                React.createElement("div", { className: 'file-3d shadow', style: {
                        width: "".concat(((_21 = master_product === null || master_product === void 0 ? void 0 : master_product.step_file) === null || _21 === void 0 ? void 0 : _21.id) && ((_22 = master_product === null || master_product === void 0 ? void 0 : master_product.drawing_file) === null || _22 === void 0 ? void 0 : _22.file)
                            ? '40%'
                            : ((_23 = master_product === null || master_product === void 0 ? void 0 : master_product.step_file) === null || _23 === void 0 ? void 0 : _23.id) && !((_24 = master_product === null || master_product === void 0 ? void 0 : master_product.drawing_file) === null || _24 === void 0 ? void 0 : _24.file)
                                ? '100% '
                                : !((_25 = master_product === null || master_product === void 0 ? void 0 : master_product.step_file) === null || _25 === void 0 ? void 0 : _25.id) && '0%'),
                    } }, ((_26 = master_product === null || master_product === void 0 ? void 0 : master_product.step_file) === null || _26 === void 0 ? void 0 : _26.file_3djs) && (React.createElement(ThreeDViewer, { url: (_27 = master_product === null || master_product === void 0 ? void 0 : master_product.step_file) === null || _27 === void 0 ? void 0 : _27.file_3djs, canvasStyle: {
                        height: '100%',
                    } }))),
                React.createElement("div", { className: 'file-2d shadow', style: {
                        width: "".concat(((_28 = master_product === null || master_product === void 0 ? void 0 : master_product.step_file) === null || _28 === void 0 ? void 0 : _28.id) && ((_29 = master_product === null || master_product === void 0 ? void 0 : master_product.drawing_file) === null || _29 === void 0 ? void 0 : _29.file)
                            ? '60%'
                            : ((_30 = master_product === null || master_product === void 0 ? void 0 : master_product.drawing_file) === null || _30 === void 0 ? void 0 : _30.file) && !((_31 = master_product === null || master_product === void 0 ? void 0 : master_product.step_file) === null || _31 === void 0 ? void 0 : _31.id)
                                ? '100% '
                                : !((_32 = master_product === null || master_product === void 0 ? void 0 : master_product.drawing_file) === null || _32 === void 0 ? void 0 : _32.file) && '0%'),
                    } }, ((_33 = master_product === null || master_product === void 0 ? void 0 : master_product.drawing_file) === null || _33 === void 0 ? void 0 : _33.file) && (React.createElement(React.Fragment, null, ((_37 = (_36 = (_35 = (_34 = master_product.drawing_file) === null || _34 === void 0 ? void 0 : _34.file) === null || _35 === void 0 ? void 0 : _35.split('.')) === null || _36 === void 0 ? void 0 : _36.pop()) === null || _37 === void 0 ? void 0 : _37.split('?')[0].toLowerCase()) == 'pdf' ? (React.createElement("embed", { key: (_38 = master_product === null || master_product === void 0 ? void 0 : master_product.drawing_file) === null || _38 === void 0 ? void 0 : _38.id, src: (_39 = master_product === null || master_product === void 0 ? void 0 : master_product.drawing_file) === null || _39 === void 0 ? void 0 : _39.file, type: "application/pdf", width: "100%", height: "100%" })) : (React.createElement("div", { className: 'flex items-center justify-center h-full' },
                    "This file is not a pdf",
                    React.createElement("a", { className: 'ml-2', href: master_product.drawing_file.file }, master_product.drawing_file.filename)))))))) : (React.createElement("div", { className: 'flex flex-col items-center text-xl justify-center w-full' }, "Please select a part on the left!"))),
            React.createElement("div", { className: 'quote-details flex flex-row', style: {
                    height: '45%',
                } },
                React.createElement("div", { className: 'quote-inputs p-2', style: {
                        width: '100%',
                        height: '100%',
                    } },
                    React.createElement("div", { className: 'line-items', style: {
                            height: '65%',
                            overflowY: 'auto',
                        } }, (_40 = master_products === null || master_products === void 0 ? void 0 : master_products.filter(function (item) { var _a; return ((_a = item.master_product) === null || _a === void 0 ? void 0 : _a.id) === (master_product === null || master_product === void 0 ? void 0 : master_product.id); })) === null || _40 === void 0 ? void 0 :
                        _40.map(function (item, index) {
                            return (React.createElement(MasterProductDetail, { key: item.master_product.id, count: new_index, position: item.position, master_product: item.master_product, setSelectedLineItems: function (keys) {
                                    handleSelectLineItems(keys);
                                }, selectedLineItems: selectedLineItems, item: function (dataMasterProduct) {
                                    setMasterProducts([]);
                                    //@ts-ignore
                                    setMasterProducts(dataMasterProduct);
                                } }));
                        }),
                        !(master_product === null || master_product === void 0 ? void 0 : master_product.id) && (React.createElement("div", { className: 'flex flex-col items-center text-xl justify-center w-full h-full' }, "Please select a part on the left!"))),
                    React.createElement("div", { className: 'quote-inputs flex flex-row w-full', style: {
                            height: '35%',
                        } },
                        React.createElement("div", { className: 'w-1/3', style: { height: '100%' } },
                            React.createElement(QuoteNotes, null)),
                        React.createElement("div", { className: 'w-1/2', style: {
                                height: '100%',
                                overflowY: 'auto',
                            } }, account && (React.createElement(ApplicationCostItems, { quote: quote, account: account, setReorderingAppCost: setReorderingAppCost }))),
                        React.createElement("div", { className: 'w-1/3 flex flex-row' },
                            React.createElement("div", { className: 'w-1/3 p-2' },
                                React.createElement("div", null,
                                    React.createElement("span", null, "Additional Requirements"),
                                    React.createElement(TooltipFlag, { field_name: 'custom_requirements' })),
                                React.createElement("div", { style: {} },
                                    React.createElement(AdditionalRequirements
                                    //@ts-ignore
                                    , { 
                                        //@ts-ignore
                                        requirements: QuoteStore.additional_requirements, onChange: function (values) {
                                            setAdditionalRequirements(values);
                                            QuoteStore.updateQuote(quote.id, {
                                                custom_requirements: values,
                                            });
                                        }, 
                                        //@ts-ignore
                                        value: additional_requirements, hide_extras: false, disabled: !quote.editable })),
                                React.createElement("div", null, "Internal Quoting Notes"),
                                React.createElement("div", null, (user === null || user === void 0 ? void 0 : user.group) && (user === null || user === void 0 ? void 0 : user.group.includes('Design Engineers')) && (React.createElement("div", { className: 'flex flex-col' },
                                    React.createElement("div", { className: 'mt-4', style: {
                                            maxWidth: 800,
                                        } },
                                        React.createElement(Paragraph, { className: 'text-left', style: {
                                                margin: 0,
                                            }, editable: {
                                                onChange: function (notes) {
                                                    var payload = {
                                                        internal_quoting_notes: notes,
                                                    };
                                                    setLoading(true);
                                                    QuoteStore.updateQuote(quote.id, payload)
                                                        .then(function () {
                                                        setLoading(false);
                                                    })
                                                        .catch(function () {
                                                        setLoading(false);
                                                    });
                                                },
                                            } }, quote === null || quote === void 0 ? void 0 : quote.internal_quoting_notes)))))),
                            React.createElement("div", { className: 'w-2/3 p-2 text-xl' },
                                React.createElement("div", { className: 'w-full flex flex-row items-end m-auto justify-end p-4' },
                                    React.createElement(Tooltip, { title: "Shift + p" },
                                        React.createElement(Button, { size: 'small', type: 'primary', icon: React.createElement(StepBackwardOutlined, null), onClick: function () { return handlePrevOrNext('prev'); } }, "Prev Part")),
                                    "\u00A0",
                                    React.createElement(Tooltip, { title: "Shift + n" },
                                        React.createElement(Button, { size: 'small', type: 'primary', icon: React.createElement(StepForwardOutlined, null), onClick: function () { return handlePrevOrNext('next'); } }, "Next Part"))),
                                React.createElement(QuoteTotals, { quote: quote })))))))));
};
export default observer(LayoutV2);
