var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { v4 } from 'uuid';
import { action, observable, runInAction, makeObservable } from 'mobx';
import { ALLOWED_FILES, stringSimilarity } from '@partsbadger/utils';
import QuoteStore from './QuoteStore';
var allowed_3d = ALLOWED_FILES.allowed_3d, allowed_2d = ALLOWED_FILES.allowed_2d;
/**
 * Extract the name from file name
 * @param filename
 * @returns {null}
 */
var get_name = function (filename) {
    return filename
        ? filename
            .split('.')
            .slice(0, -1)
            .join('.')
            .replace(/[^0-9a-z]/gi, '')
        : '';
};
/**
 * Check if a file is 2d of 3d file based on list of extensions
 * @param filename
 * @returns {string}
 */
var is3dFile = function (filename) {
    var extension = filename.split('.').pop();
    return allowed_3d.find(function (ext) { return ext.toLowerCase() === (extension === null || extension === void 0 ? void 0 : extension.toLowerCase()); });
};
var is2dFile = function (filename) {
    var extension = filename.split('.').pop();
    return allowed_2d.find(function (ext) { return ext.toLowerCase() === (extension === null || extension === void 0 ? void 0 : extension.toLowerCase()); });
};
var PartFiles = /** @class */ (function () {
    function PartFiles() {
        var _this = this;
        this.FILE_3D = '3D File';
        this.FILE_2D = '2D File';
        this.FILE_OTHERS = 'Additional File';
        /**
         * @type {Array} of objects :
         * @format [{
         *     id: unique_id
         *     file2d:
         *     file3:
         *     fileothers:
         *     similarity: 0 to 1, 1 is the exact name
         * }]
         */
        this.fileParts = [];
        this.sortedBy = {};
        this.isZipFile = function (filename) {
            var _a, _b;
            return ((_b = (_a = filename.split('.')) === null || _a === void 0 ? void 0 : _a.pop()) === null || _b === void 0 ? void 0 : _b.toLowerCase()) === 'zip';
        };
        /**
         * Search a spot to a new file, search the spot by similarity name or add new line
         * @param file (object)
         */
        this.addPart = function (file) {
            var _a;
            var payload = {
                uid: v4(),
            };
            var type = 'Additional File'; // Current file type
            var searchFileType = 'all'; // Type of file where we have to find match
            if (is3dFile(file.name)) {
                type = '3D File';
                searchFileType = '2D File';
            }
            else if (is2dFile(file.name)) {
                type = '2D File';
                searchFileType = '3D File';
            }
            else {
                type = 'Additional File';
                searchFileType = 'all'; // For other files we should to search on the 3d or 2d matches
            }
            // Check if there is file with the same name
            var index = _this.findExactName(file.name, searchFileType);
            var similarity = 1;
            //If there is not files with the same name try with fuzzy search
            if (index === -1) {
                var result = _this.fuzzySearch(file.name, searchFileType);
                index = result.index;
                similarity = result.similarity;
            }
            if (type === 'Additional File') {
                if (index >= 0) {
                    _this.fileParts[index][type] =
                        ((_a = _this.fileParts[index][type]) === null || _a === void 0 ? void 0 : _a.length) > 0 ? __spreadArray(__spreadArray([], _this.fileParts[index][type], true), [file], false) : [file];
                }
                else {
                    payload[type] = [file];
                    _this.setParts(__spreadArray(__spreadArray([], _this.fileParts, true), [payload], false));
                }
                return;
            }
            // Replace the file with the files founded
            if (index >= 0 && similarity > _this.fileParts[index]['similarity']) {
                var backup = _this.fileParts[index][type];
                _this.fileParts[index][type] = file;
                _this.fileParts[index]['similarity'] = similarity;
                if (backup) {
                    // if there is a file search a spot for that file
                    _this.addPart(backup);
                }
            }
            else {
                // Add a new line
                payload[type] = file;
                payload['similarity'] = similarity;
                payload['uploading'] = false;
                payload['progress'] = 0;
                _this.setParts(__spreadArray(__spreadArray([], _this.fileParts, true), [payload], false));
            }
        };
        makeObservable(this, {
            fileParts: observable,
            sortedBy: observable,
            // Actions
            setParts: action,
            isZipFile: action,
            addPart: action,
            removePart: action,
            addFile: action,
            removeFile: action,
            swapParts: action,
            swapParts2: action,
            uploadPart: action,
            hasParts: action,
            count: action,
            sortBy: action,
        });
    }
    PartFiles.prototype.setParts = function (parts) {
        var _this = this;
        runInAction(function () {
            _this.fileParts = parts;
        });
    };
    /**
     * Find for a exact file name
     * @param filename
     * @param type {String}  type of file, '3D File' or '2D File' or "all"
     * @returns {number} Index founded else -1 if not found
     */
    PartFiles.prototype.findExactName = function (filename, type) {
        return this.fileParts.findIndex(function (item) {
            if (type === 'all') {
                var a = item['2D File'];
                var b = item['3D File'];
                return ((a && get_name(a === null || a === void 0 ? void 0 : a.name) === get_name(filename)) || (b && get_name(b === null || b === void 0 ? void 0 : b.name) === get_name(filename)));
            }
            var currentFile = item[type];
            return currentFile && get_name(currentFile === null || currentFile === void 0 ? void 0 : currentFile.name) === get_name(filename);
        });
    };
    /**
     * Find similar file  with fuzzy search
     * @param filename
     * @param type {String}  type of file to search in, '3D File' or '2D File' or "all" to compare with both
     * @return {index, similarity} Index founded else -1 if not found, similarity 0 - 1
     */
    PartFiles.prototype.fuzzySearch = function (filename, type) {
        // Exclude items with similarity 1, those are exact names
        // Exclude items if the similarity found is less than the existing ones
        var found = this.fileParts
            .filter(function (item) { return item.similarity < 1 && item[type]; })
            .map(function (item) {
            var _a;
            var similarity = 0;
            if (type === 'all') {
                var a = item['2D File'];
                var b = item['3D File'];
                var similarity_a = stringSimilarity(get_name(a === null || a === void 0 ? void 0 : a.name), get_name(filename));
                var similarity_b = stringSimilarity(get_name(b === null || b === void 0 ? void 0 : b.name), get_name(filename));
                similarity = similarity_a > similarity_b ? similarity_a : similarity_b;
            }
            else {
                similarity = stringSimilarity(get_name((_a = item[type]) === null || _a === void 0 ? void 0 : _a.name), get_name(filename));
            }
            return {
                item: item,
                similarity: similarity,
            };
        })
            .filter(function (f) { return f.similarity > 0 && f.similarity > f.item.similarity; })
            .sort(function (a, b) { return b.similarity - a.similarity; });
        if (found.length > 0) {
            var index = this.fileParts.findIndex(function (item) {
                return item.uid === found[0].item.uid;
            });
            return {
                index: index,
                similarity: found[0].similarity,
            };
        }
        return {
            index: -1,
            similarity: 0,
        };
    };
    PartFiles.prototype.removePart = function (item) {
        this.setParts(__spreadArray([], this.fileParts.filter(function (i) { return i.uid !== item.uid; }), true));
    };
    PartFiles.prototype.addFile = function (index, fileType, file) {
        if (fileType === 'Additional File') {
            var files = this.fileParts[index][fileType] || [];
            this.fileParts[index][fileType] = __spreadArray(__spreadArray([], files, true), [file], false);
        }
        else {
            this.fileParts[index][fileType] = file;
        }
    };
    PartFiles.prototype.removeFile = function (item, fileType, file) {
        this.setParts(__spreadArray([], this.fileParts.filter(function (i) {
            if (i.uid !== item.uid) {
                return i;
            }
            if (fileType === 'Additional File') {
                if (file) {
                    i[fileType] = i[fileType].filter(function (f) { return f.name !== file.name; });
                }
            }
            else {
                i[fileType] = null;
            }
            return i;
        }), true));
    };
    /**
     * Swap a part file
     * @param index_from: index from the file was picked
     * @param index_to: index where the file was dropped
     * @param FileTypeFrom: Spot file type where the file was picker
     * @param FileTypeTo: Spot File type were the file is dropped
     * @param file : file object
     */
    PartFiles.prototype.swapParts = function (index_from, index_to, FileTypeFrom, FileTypeTo, file) {
        if (FileTypeTo === 'Additional File' && (FileTypeFrom === '2D File' || FileTypeFrom === '3D File')) {
            // Remove the file from the current position 2d or 3d
            this.fileParts[index_from][FileTypeFrom] = null;
            // Append file to the other files
            var current_other_files = this.fileParts[index_to]['Additional File'] || [];
            this.fileParts[index_to]['Additional File'] = __spreadArray(__spreadArray([], current_other_files, true), [file], false);
        }
        else if (FileTypeFrom === 'Additional File' && (FileTypeTo === '2D File' || FileTypeTo === '3D File')) {
            // Remove the file from file others on the previous position
            this.fileParts[index_from]['Additional File'] = this.fileParts[index_from]['Additional File'].filter(function (f) {
                return f.name !== file.name;
            });
            // save if there is a file in the destiny spot
            var current = this.fileParts[index_to][FileTypeTo];
            // Move to the other files if there was a file
            if (current) {
                var current_other_files = this.fileParts[index_from]['Additional File'] || [];
                this.fileParts[index_from]['Additional File'] = __spreadArray(__spreadArray([], current_other_files, true), [current], false);
            }
            // Put the file on the 2d or 3d spot
            this.fileParts[index_to][FileTypeTo] = file;
        }
        else if (FileTypeFrom === 'Additional File' && FileTypeTo === 'Additional File') {
            // Remove the file from file others on the previous position, filtering by name
            this.fileParts[index_from]['Additional File'] = this.fileParts[index_from]['Additional File'].filter(function (f) {
                return f.name !== file.name;
            });
            var current_other_files = this.fileParts[index_to]['Additional File'] || [];
            this.fileParts[index_to]['Additional File'] = __spreadArray(__spreadArray([], current_other_files, true), [file], false);
        }
        else {
            var file_from = this.fileParts[index_from][FileTypeFrom];
            var file_to = this.fileParts[index_to][FileTypeTo];
            this.fileParts[index_to][FileTypeTo] = file_from;
            this.fileParts[index_from][FileTypeFrom] = file_to;
        }
    };
    /**
     * Swap a part file
     * @param index_from
     * @param index_to
     * @param fileType type of file
     * @param file
     */
    PartFiles.prototype.swapParts2 = function (index_from, index_to, fileType, file) {
        if (fileType === 'Additional File') {
            var destination_files = this.fileParts[index_to][fileType];
            var origin_files = this.fileParts[index_from][fileType];
            // Append to the destination files
            this.fileParts[index_to][fileType] = (destination_files === null || destination_files === void 0 ? void 0 : destination_files.length) ? __spreadArray(__spreadArray([], destination_files, true), [file], false) : [file];
            // Remove from the original position
            this.fileParts[index_from][fileType] = origin_files === null || origin_files === void 0 ? void 0 : origin_files.filter(function (f) {
                return f.name !== file.name;
            });
        }
        else {
            var file_to = this.fileParts[index_to][fileType];
            this.fileParts[index_to][fileType] = this.fileParts[index_from][fileType];
            this.fileParts[index_from][fileType] = file_to;
        }
    };
    PartFiles.prototype.uploadPart = function (item) {
        var _this = this;
        var _a, _b;
        var index = this.fileParts.findIndex(function (i) { return i.uid === item.uid; });
        this.fileParts[index]['uploading'] = true;
        var formData = new FormData();
        if (item['3D File']) {
            formData.append('file_3d', item['3D File']);
        }
        if (item['2D File']) {
            formData.append('file_2d', item['2D File']);
        }
        if ((_a = item['Additional File']) === null || _a === void 0 ? void 0 : _a.length) {
            item['Additional File'].map(function (f) { return formData.append('file_others', f); });
        }
        // Send the exact row position of the file in the drag and drop
        formData.append('position', (index + 1).toString());
        if ((_b = QuoteStore.quote) === null || _b === void 0 ? void 0 : _b.email) {
            formData.append('email', QuoteStore.quote.email);
        }
        var onUploadProgress = function (progressEvent) {
            var progress = (progressEvent.loaded / progressEvent.total) * 100;
            try {
                _this.fileParts[index]['progress'] = Number(progress === null || progress === void 0 ? void 0 : progress.toFixed(0)) || 0;
            }
            catch (e) {
                console.error(e);
            }
        };
        QuoteStore.newLineItemWithFiles(formData, onUploadProgress).finally(function () {
            _this.removePart(item);
        });
    };
    PartFiles.prototype.hasParts = function () {
        return this.fileParts.length > 0;
    };
    PartFiles.prototype.count = function () {
        return this.fileParts.length;
    };
    PartFiles.prototype.sortBy = function (fileType) {
        this.sortedBy = fileType;
        this.fileParts = this.fileParts.slice().sort(function (item1, item2) {
            var _a, _b, _c, _d;
            var a = (_b = (_a = item1[fileType]) === null || _a === void 0 ? void 0 : _a.name) === null || _b === void 0 ? void 0 : _b.toLowerCase();
            var b = (_d = (_c = item2[fileType]) === null || _c === void 0 ? void 0 : _c.name) === null || _d === void 0 ? void 0 : _d.toLowerCase();
            if (a < b) {
                return -1;
            }
            if (b > a) {
                return 1;
            }
            return 0; // Are equal
        });
    };
    return PartFiles;
}());
var partStore = new PartFiles();
export default partStore;
