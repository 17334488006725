import React, { useState } from 'react';
import { Popover, InputNumber, Button, Radio } from 'antd';
import { EditOutlined } from '@ant-design/icons';
var EditDiscountPopover = function (_a) {
    var record = _a.record, onUpdateItem = _a.onUpdateItem, loading = _a.loading;
    var _b = useState('percentage'), discountOption = _b[0], setDiscountOption = _b[1];
    var _c = useState(false), open = _c[0], setOpen = _c[1];
    var _d = useState(0), discountValue = _d[0], setDiscountValue = _d[1];
    var hide = function () {
        setOpen(false);
    };
    var handleOpenChange = function (newOpen) {
        setOpen(newOpen);
    };
    var onChange = function (e) {
        setDiscountOption(e.target.value);
    };
    var discountContent = function () {
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement(Radio.Group, { defaultValue: "percentage", onChange: onChange },
                    React.createElement(Radio, { value: "percentage" },
                        "Percentage",
                        discountOption === 'percentage' && (React.createElement(InputNumber, { size: "small", style: { width: '60px' }, className: "ml-1", defaultValue: 0, min: 0, max: 100, formatter: function (value) { return "".concat(value, "%"); }, onChange: function (value) {
                                if (value !== null) {
                                    var calculateDiscount = (value / 100) * record.total;
                                    if (calculateDiscount > 0) {
                                        setDiscountValue(parseFloat(calculateDiscount.toFixed(2)));
                                    }
                                }
                            } }))),
                    React.createElement(Radio, { value: "direct" },
                        "Direct Price Reduction",
                        discountOption === 'direct' && (React.createElement(InputNumber, { size: "small", min: 0, max: record.total, className: "ml-1", onChange: function (value) {
                                if (value !== null) {
                                    setDiscountValue(parseFloat(value.toFixed(2)));
                                }
                            } }))))),
            React.createElement("div", { className: "mt-3" },
                React.createElement(Button, { type: "primary", size: "small", className: "mr-2", loading: loading, onClick: function () {
                        hide();
                        onUpdateItem(discountValue);
                    } }, "Done"),
                React.createElement(Button, { size: "small", onClick: hide, loading: loading }, "Cancel"))));
    };
    return (React.createElement(Popover, { content: discountContent(), title: React.createElement("span", { className: "bold" }, "Set Discount"), trigger: "click", open: open, onOpenChange: handleOpenChange },
        React.createElement(Button, { type: "text", icon: React.createElement(EditOutlined, null) })));
};
export default EditDiscountPopover;
