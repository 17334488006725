import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Modal, notification, Radio, Row, Space, Table } from 'antd';
import { ReworkHeader } from './components/ReworkHeader';
import { useHistory, useParams } from 'react-router-dom';
import { SalesOrderStore } from '../../../stores/SalesOrderStore';
import { ICreateSalesOrderRework, OrderLineItemProps, SalesOrderProps } from '@partsbadger/types';
import { ErrorRender } from '@partsbadger/library';
import { ReworkTable } from './components/ReworkTable';

type Params = {
    sales_order_zoho_id: string;
};

type InvoiceStatus = 'Paid' | 'Unpaid';

type IReworkItems = {
    sales_order_item_id: number;
    name: string;
    quantity: number;
    quantity_ordered: number;
    invoice_status: string | null;
};

type CustomOrderLineItemProps = {
    id: number;
    sales_order_item_id: number;
    name: string;
    quantity: number;
    quantity_ordered: number;
    invoice_status: string | null;
};

export const SalesOrderReworkPage = () => {
    const params = useParams<Params>();
    const { sales_order_zoho_id } = params;

    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [showModalSalesOrderItems, setShowModalSalesOrderItems] = useState<boolean>(false);
    const [selectedSalesOrderItemsRowKeys, setSelectedSalesOrderItemsRowKeys] = useState<React.Key[]>([]);
    const [tableKey, setTableKey] = useState(0);

    const [sales_order, setSalesOrder] = useState<SalesOrderProps>();
    const [sales_order_items, setSalesOrderItems] = useState<CustomOrderLineItemProps[]>([]);
    const [showErrors, setShowErrors] = useState<boolean>(false);
    const [type, setType] = useState<string | null>(null);
    const [reworkItems, setReworkItems] = useState<IReworkItems[]>([]);
    const [itemsWithZeroQuantity, setItemsWithZeroQuantity] = useState<number[]>([]);

    useEffect(() => {
        handleGetSalesOrder();
    }, [sales_order_zoho_id]);

    const handleCreateSalesOrderRework = () => {
        setShowErrors(true);
        if (!type) {
            notification.error({ message: 'Please select a type.', duration: 5 });
            return;
        }

        if (reworkItems.length <= 0) {
            notification.error({ message: 'Please add at least one item to sales order rework.', duration: 5 });
            return;
        }

        const itemsWithZeroQuantityIds = reworkItems
            .filter(item => item.quantity === null || item.quantity === 0)
            .map(item => item.sales_order_item_id);

        if (itemsWithZeroQuantityIds.length > 0) {
            notification.error({
                message: 'One or more items have a quantity of 0.',
                duration: 5,
            });
            setItemsWithZeroQuantity(itemsWithZeroQuantityIds);
            return;
        }

        const payload: ICreateSalesOrderRework = {
            type_sales_order: type === 'REDO' ? 'Redo' : 'Fixing',
            line_items: reworkItems.map(item => {
                return {
                    id: item.sales_order_item_id,
                    quantity: item.quantity,
                };
            }),
        };

        setLoading(true);

        SalesOrderStore.createRework(sales_order_zoho_id, payload)
            .then(response => {
                notification.success({ message: 'Sales order rework created successfully.', duration: 5 });
                history.push(`/sales-order/${response['sales_order_id']}/`);
            })
            .catch(() => {
                notification.error({ message: 'Error creating sales order rework.', duration: 5 });
            })
            .finally(() => setLoading(false));
        setShowErrors(false);
    };

    const invoiceStatusMap: Record<InvoiceStatus, boolean> = {
        Paid: false,
        Unpaid: true,
    };

    const handleGetSalesOrder = async () => {
        try {
            setLoading(true);
            const sales_order = await SalesOrderStore.getById(sales_order_zoho_id);
            setLoading(false);
            setSalesOrder(sales_order);

            const sales_order_items = sales_order.line_items;
            sales_order_items.forEach((item: OrderLineItemProps) => {
                if (item.invoice_status) {
                    item.credit_memo_needed = invoiceStatusMap[item.invoice_status as InvoiceStatus] ?? false;
                } else {
                    item.credit_memo_needed = false;
                }
            });

            const reworkItems = sales_order.line_items.map((item: CustomOrderLineItemProps) => {
                return {
                    id: item.id,
                    sales_order_item_id: item.id,
                    name: item.name,
                    quantity: 0,
                    quantity_ordered: item.quantity,
                    invoice_status: item.invoice_status ?? null,
                };
            });

            setSalesOrderItems(reworkItems);
        } catch (err: any) {
            notification.error({
                message: <ErrorRender error={err} />,
                placement: 'topRight',
            });
        }
    };

    const handleUpdateItem = (sales_order_item_id: number, key: string, value: any) => {
        if (reworkItems.length > 0) {
            const item = reworkItems.find(item => item.sales_order_item_id === sales_order_item_id);
            if (item) {
                // @ts-ignore
                item[key] = value;
                setReworkItems([...reworkItems]);
            }
        }
    };

    const handleRemoveItem = (sales_order_item_id: number, issue_quantity: number) => {
        // Remove item from list only in frontend
        if (reworkItems.length > 0 && sales_order) {
            const order_line_items = sales_order.line_items.map((item: OrderLineItemProps) => {
                return {
                    id: item.id,
                    sales_order_item_id: item.id,
                    name: item.name,
                    quantity: issue_quantity,
                    quantity_ordered: item.quantity,
                    invoice_status: item.invoice_status ?? null,
                };
            });
            const removedItem = order_line_items.find(item => item.sales_order_item_id === sales_order_item_id);
            if (removedItem) {
                setSalesOrderItems([...sales_order_items, removedItem]);
            }
            const newItems = reworkItems.filter(item => item.sales_order_item_id !== sales_order_item_id);
            setReworkItems(newItems);
        }
    };

    const handleAddItemsRework = () => {
        selectedSalesOrderItemsRowKeys.forEach((key: React.Key) => {
            const item = sales_order_items.find(item => item.id === Number(key));
            if (item) {
                const reworkItem: IReworkItems = {
                    sales_order_item_id: item.id,
                    name: item.name,
                    quantity: item.quantity,
                    quantity_ordered: item.quantity_ordered,
                    invoice_status: item.invoice_status ?? null,
                };

                setReworkItems(prevReworkItems => {
                    const itemExists = prevReworkItems.some(
                        rework => rework.sales_order_item_id === reworkItem.sales_order_item_id
                    );

                    if (!itemExists) {
                        return [...prevReworkItems, reworkItem];
                    }
                    return prevReworkItems;
                });
            }
        });
        const newItems = sales_order_items.filter(item => !selectedSalesOrderItemsRowKeys.includes(String(item.id)));
        setSalesOrderItems(newItems);
        handleCloseModal();
    };

    const onSelectChange = (newSelectedRowKeys: React.Key[]) => {
        setSelectedSalesOrderItemsRowKeys(newSelectedRowKeys);
    };

    const handleSelectSalesOrderItems = {
        selectedSalesOrderItemsRowKeys,
        onChange: onSelectChange,
    };

    const handleCloseModal = () => {
        setSelectedSalesOrderItemsRowKeys([]);
        setShowModalSalesOrderItems(false);
        setTableKey(prevKey => prevKey + 1);
    };

    const handleGoBack = () => {
        history.push('/sales-orders');
    };

    return (
        <>
            <Card
                style={{
                    padding: 0,
                    border: 'none',
                }}
                bodyStyle={{
                    padding: 0,
                }}
            >
                {sales_order && (
                    <>
                        <ReworkHeader
                            handleGoBack={handleGoBack}
                            sales_order_name={sales_order.name}
                            created_time={sales_order.created_time}
                            full_name={sales_order.owner.fullname}
                        />

                        <Row className={'p-4'} gutter={16}>
                            <Col span={8}>
                                <div className={'flex flex-row-4'}>
                                    <div className={'font-bold px-2'}>Sales Order:</div>
                                    <div>{sales_order.name}</div>
                                </div>
                            </Col>
                            <Col span={8}>
                                <div className={'flex flex-row-4'}>
                                    <div className={'font-bold px-2'}>Rework Type:</div>
                                    <Radio.Group
                                        style={{ marginTop: -5 }}
                                        onChange={e => setType(e.target.value)}
                                        value={type}
                                        buttonStyle={'solid'}
                                    >
                                        <Space direction="horizontal">
                                            <Radio value="REDO">REDO</Radio>
                                            <Radio value="FIXING">FIXING</Radio>
                                        </Space>
                                    </Radio.Group>
                                </div>
                                {type === null && showErrors && (
                                    <div className={'text-red-500 ml-2'}>Please select a type.</div>
                                )}
                            </Col>
                        </Row>
                    </>
                )}

                <Row className={'p-2'}>
                    <Col span={24} className={'flex flex-row justify-end mt-4 mb-4'}>
                        <Button type={'primary'} onClick={() => setShowModalSalesOrderItems(true)}>
                            Add Sales Order Items
                        </Button>
                    </Col>
                    <Col span={24}>
                        <ReworkTable
                            handleUpdateItem={handleUpdateItem}
                            handleRemove={handleRemoveItem}
                            items={
                                reworkItems.map((item: IReworkItems) => {
                                    return {
                                        id: item.sales_order_item_id,
                                        name: item.name,
                                        quantity: item.quantity,
                                        quantity_ordered: item.quantity_ordered,
                                        invoice_status: item.invoice_status,
                                    };
                                }) || []
                            }
                            loading={loading}
                            itemsWithZeroQuantity={itemsWithZeroQuantity}
                        />
                    </Col>
                    <Col span={24} className={'flex flex-row justify-end mt-4'}>
                        <Button type={'primary'} onClick={handleCreateSalesOrderRework}>
                            Submit to Review
                        </Button>
                    </Col>
                </Row>
            </Card>

            <Modal
                width={800}
                open={showModalSalesOrderItems}
                title="Please select sales order items to rework"
                onCancel={handleCloseModal}
                footer={[
                    <>
                        <Button key="back" onClick={handleCloseModal}>
                            Close
                        </Button>

                        <Button
                            key="submit"
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            onClick={handleAddItemsRework}
                            disabled={selectedSalesOrderItemsRowKeys.length <= 0}
                        >
                            Add to Rework
                        </Button>
                    </>,
                ]}
            >
                <Table
                    key={tableKey}
                    columns={[
                        {
                            key: 'name',
                            title: 'Part Name',
                            align: 'center',
                            render: (r: CustomOrderLineItemProps) => {
                                return r.name;
                            },
                        },
                        {
                            key: 'quantity',
                            title: 'Quality Issue Quantity',
                            align: 'center',
                            render: (r: CustomOrderLineItemProps) => {
                                return r.quantity === 0 ? '' : r.quantity;
                            },
                        },
                    ]}
                    dataSource={sales_order_items}
                    loading={loading}
                    pagination={false}
                    rowSelection={handleSelectSalesOrderItems}
                    rowKey={(record: CustomOrderLineItemProps) => String(record.id)}
                />
            </Modal>
        </>
    );
};
